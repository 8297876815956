import { isNil } from 'lodash-es';
import mixpanel from 'mixpanel-browser';
import { useEffect, RefObject, useState, useContext } from 'react';
import analytics from 'utils/analytics';

import { ANALYTICS } from './constants/analytics';
import { MIXPANEL_API_KEY } from './constants/mixpanel';
import { logError } from './helpers';
import { UserContext } from './hooks/useUserContext/context';
import { LineItem, User, FormattedProductForTracking, VariantsSkusType } from './types';

export interface TrackProps {
  event: string;
  data: {
    [prop: string]:
      | LineItem
      | string
      | number
      | boolean
      | undefined
      | string[]
      | FormattedProductForTracking[]
      | VariantsSkusType;
  };
}

// LineItems could come without a variant sku
const lineItemHasVariantSku = (lineItem: LineItem): boolean => !!lineItem.variant_sku

export const skusForMixpanel = (lineItems: LineItem[] | undefined | null): VariantsSkusType => {
  if(isNil(lineItems)) return [];

  return lineItems.filter(lineItemHasVariantSku)
                  .map(lineItem => lineItem.variant_sku!);
}

let initialized = false;

export const init = (): Promise<void> =>
  new Promise((resolve, reject) => {
    if (MIXPANEL_API_KEY) {
      if (!initialized) {
        mixpanel.init(MIXPANEL_API_KEY);
        initialized = true;
        // @ts-ignore
        window.mixpanel = mixpanel;
      }

      return resolve();
    }

    return reject();
  });

export const identify = (id: string): Promise<void> =>
  init()
    .then(() => {
      mixpanel.identify(id);

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

export const alias = (id: string): Promise<void> =>
  init()
    .then(() => {
      mixpanel.alias(id);

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

const getGlobalAnalyticsData = (): any => {
  // @ts-ignore
  const current_campaigns = window._CURRENT_CAMPAIGNS_EW_VWO || [
    { test: 'test-exp', variant: 'control' },
  ];
  // @ts-ignore
  return current_campaigns.reduce((acc: any, next: any) => {
    const campaign = { [next.test]: next.variant };
    return { ...acc, ...campaign };
  }, {});
};

// @ts-ignore
export const track = (props: TrackProps, cb?: RequestOptions): Promise<void> =>
  init()
    .then(() => {
      const globalAnalyticsData = getGlobalAnalyticsData();
      // @ts-ignore
      mixpanel.track(
        props.event,
        { ...props.data, ...globalAnalyticsData },
        cb,
      );

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

export const setUserInfo = (user: User): Promise<void> =>
  init()
    .then(() => {
      const { id, loggedIn, isMember } = user;
      const userInfo = {
        'Is Member': isMember,
        'Is Logged In': loggedIn,
      };

      if (id) {
        mixpanel.people.set(userInfo);
        mixpanel.identify(id.toString());
      }

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

/*
 * handle events for analytics tracking
 *
 * required attribute on the HTML element:
 * * data-analytics-event
 *
 * optional attributes:
 * * data-analytics-label
 * * data-analytics-category
 * * data-analytics-test-id
 *
 */
// export function eventHandler(event: Event) {
export const eventHandler = (event: Event): void => {
  event.preventDefault();
  const clickedElement = event.target as HTMLAnchorElement;
  // if the element that was clicked was a child of the element with the data-analytics-event
  const element = clickedElement.closest(
    '[data-analytics-event]',
  ) as HTMLAnchorElement;
  const { href } = element;
  // tracking requires data attributes
  if (!element || !element.dataset) return;

  const { analyticsEvent, analyticsLabel, analyticsCategory, analyticsTestId } =
    element.dataset;

  // event is required
  if (!analyticsEvent) return;
  track(
    {
      data: {
        category: analyticsCategory,
        label: analyticsLabel,
        test_id: analyticsTestId,
      },
      event: analyticsEvent,
    },
    () => {
      if (href) {
        window.location.href = href;
      }
    },
  );
};

const handleTrackableLinkClick = (e: Event): void => {
  eventHandler(e);
};

const useAttachTrackingToLinks = (ref: RefObject<HTMLElement>): void => {
  useEffect(() => {
    let trackableLinks: NodeListOf<HTMLAnchorElement> | null;
    if (ref.current) {
      trackableLinks = ref.current.querySelectorAll('[data-analytics-event]');
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.addEventListener('click', handleTrackableLinkClick),
        );
      }
    }

    return (): void => {
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.removeEventListener('click', handleTrackableLinkClick),
        );
      }
    };
  }, [ref.current]);
};

export const trackEvent = (label: string): void => {
  track({
    event: label,
    data: {},
  });
};

const useIsVisible = (ref: RefObject<HTMLDivElement>, rootMargin = '0px') => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) observer.unobserve(currentElement);
    };
  }, []);

  return isVisible;
};
export interface ComponentVisibilityTrackingData {
  label: string;
  slug?: string;
}
export const trackComponentVisibility = (
  ref: RefObject<HTMLDivElement>,
  trackingData: ComponentVisibilityTrackingData,
): void => {
  try {
    const isVisible = useIsVisible(ref);
    const {
      userData: { loggedIn, isMember, membershipType, membershipState },
    } = useContext(UserContext);
    const membershipProps = {
      isMember: Boolean(isMember),
      loggedIn: isMember ? true : loggedIn,
      membershipType: membershipType ? membershipState : undefined,
      membershipState: membershipState ? membershipState : undefined,
    };

    useEffect(() => {
      if (isVisible) {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
          data: {
            ...trackingData,
            ...membershipProps,
          },
        });
      }
    }, [isVisible]);
  } catch (err: any) {
    logError(err?.message, {
      component: 'useMembershipIntake',
      method: 'onSignUp',
    });
  }
};

export default {
  identify,
  alias,
  setUserInfo,
  track,
  eventHandler,
  useAttachTrackingToLinks,
  trackEvent,
};
