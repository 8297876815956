import { MembershipCategory } from '../types/membershipSkus';

/***
 * Provides a mapping between the Membership Product test kit Variant skus
 * and their corresponding tesk kit Product slug.
 *
 * We currently keep the Membership kit variants on the Membership Products, so
 * we need a way to associate those items. We have a similar map in Store, since
 * we pass the variant in the Credit Redemption flow. Perhaps it could be simplified
 * if we passed the product sku instead.
 */

export const membershipSkuMapping: Record<string, MembershipCategory> = {
  control: {
    'energy-&-weight': {
      label: 'Energy & Weight Tests',
      items: {
        thyroid: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'thyroid-box@2x',
          originalPrice: '$99',
          pdpSlug: 'thyroid-test',
          title: 'Thyroid Test',
          variantInfo: {
            'thyroid-test': 512,
          },
        },
        metabolism: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'metabolism-box@2x',
          originalPrice: '$49',
          pdpSlug: 'metabolism',
          title: 'Metabolism Test',
          variantInfo: {
            metabolism: 513,
          },
        },
        testosterone: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'testosterone-box@2x',
          originalPrice: '$49',
          pdpSlug: 'testosterone-test',
          title: 'Testosterone Test',
          variantInfo: {
            'testosterone-test': 514,
          },
        },
        'vitamin-d': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'v-itamin-d-box@2x',
          originalPrice: '$49',
          pdpSlug: 'vitamin-d-test',
          title: 'Vitamin D Test',
          variantInfo: {
            'vitamin-d-test': 515,
          },
        },
        'vitamin-d-and-inflammation': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'vitamin-d-inflammation-box@2x',
          originalPrice: '$99',
          pdpSlug: 'vitamin-d-and-inflammation-test',
          title: 'Vitamin D & Inflammation Test',
          variantInfo: {
            'vitamin-d-and-inflammation-test': 516,
          },
        },
      },
    },
    'heart-health-&-screening': {
      label: 'Heart Health & Screening Tests',
      items: {
        hba1c: {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hba1c',
          title: 'HbA1c Test',
          variantInfo: {
            hba1c: 510,
          },
        },
        'cholesterol-and-lipids': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'cholesterol-lipids-box@2x',
          originalPrice: '$49',
          pdpSlug: 'cholesterol-and-lipids-test',
          title: 'Cholesterol & Lipids Test',
          variantInfo: {
            'cholesterol-and-lipids-test': 511,
          },
        },
        'fit-colon-cancer-screening': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'fit-colorectal-cancer-box@2x',
          originalPrice: '$49',
          pdpSlug: 'fit-colon-cancer-screening-test',
          title: 'FIT Colon Cancer Screening Test',
          variantInfo: {
            'fit-colon-cancer-screening-test': 517,
          },
        },
        'hpv-test-female': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'hpv-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hpv-test-female',
          title: 'HPV Test - Female',
          variantInfo: {
            'hpv-test-female': 518,
          },
        },
        'heart-health': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$99',
          pdpSlug: 'heart-health-test',
          title: 'Heart Health Test',
          variantInfo: {
            'heart-health-test': 1071,
          },
        },
      },
    },
    'sexual-health': {
      label: 'Sexual Health Tests',
      items: {
        'chlamydia-and-gonorrhea': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'chlamydia-gonorrhea-box@2x',
          originalPrice: '$49',
          pdpSlug: 'chlamydia-gonorrhea-test',
          title: 'Chlamydia & Gonorrhea Test',
          variantInfo: {
            'chlamydia-gonorrhea-test': 505,
          },
        },
        syphillis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'syphilis-box@2x',
          title: 'Syphilis Test',
          originalPrice: '$49',
          pdpSlug: 'syphilis-test',
          variantInfo: {
            'syphilis-test': 506,
          },
        },
        'hepatitis-c': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hepatitis-c-test',
          title: 'Hepatitis C Test',
          variantInfo: {
            'hepatitis-c-test': 507,
          },
        },
        hiv: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hiv-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hiv-test',
          title: 'HIV Test',
          variantInfo: {
            'hiv-test': 508,
          },
        },
        trichomoniasis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'trichomoniasis-box@2x',
          originalPrice: '$49',
          pdpSlug: 'trichomoniasis-test',
          title: 'Trichomoniasis Test',
          variantInfo: {
            'trichomoniasis-test': 509,
          },
        },
      },
    },
  },
  sti: {
    'sexual-health': {
      label: 'Sexual Health Tests',
      items: {
        'chlamydia-and-gonorrhea': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'chlamydia-gonorrhea-box@2x',
          originalPrice: '$49',
          pdpSlug: 'chlamydia-gonorrhea-test',
          title: 'Chlamydia & Gonorrhea Test',
          variantInfo: {
            'chlamydia-gonorrhea-test': 375,
          },
        },
        syphillis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'syphilis-box@2x',
          originalPrice: '$49',
          pdpSlug: 'syphilis-test',
          title: 'Syphilis Test',
          variantInfo: {
            'syphilis-test': 376,
          },
        },
        'hepatitis-c': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hepatitis-c-test',
          title: 'Hepatitis C Test',
          variantInfo: {
            'hepatitis-c-test': 377,
          },
        },
        hiv: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hiv-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hiv-test',
          title: 'HIV Test',
          variantInfo: {
            'hiv-test': 378,
          },
        },
        trichomoniasis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'trichomoniasis-box@2x',
          originalPrice: '$49',
          pdpSlug: 'trichomoniasis-test',
          title: 'Trichomoniasis Test',
          variantInfo: {
            'trichomoniasis-test': 379,
          },
        },
      },
    },
  },
};
