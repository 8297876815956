// Custom interceptor to add a fresh JWT to each request
import axios, { AxiosRequestConfig } from 'axios';
import { QueryClient } from 'react-query';

import { getAuthToken } from '../cartHelpers';
import { STORE_ROOT } from '../constants/urls';

const jwtInterceptor = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  /* this should be set by the /sessions/status.json endpoint in sessionApi.ts on load*/
  if (config.headers.Authorization) return config;

  // TODO: we should be able to remove this after we confirm that the auth token is being set correctly on load in sessionApi.ts
  const authToken = await getAuthToken();
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  }
  return Promise.reject(new Error('No authorization token found'));
};

/**
 * Store
 *
 * api axios instance
 */

export const StoreApi = axios.create({
  baseURL: `${STORE_ROOT}/aapi`,
});

// Add default content type to headers
StoreApi.defaults.headers.common['Content-Type'] = 'application/json';
// Add interceptor to instance
StoreApi.interceptors.request.use(jwtInterceptor, (error) => {
  Promise.reject(error);
});

/**
 * Stripe
 *
 * api axios instance
 */
export const StripeApi = axios.create({
  baseURL: `${STORE_ROOT}/stripe_processor`,
});
// Add default content type to headers
StripeApi.defaults.headers.common['Content-Type'] = 'application/json';
// Add interceptor to instance
StripeApi.interceptors.request.use(jwtInterceptor, (error) => {
  Promise.reject(error);
});
StripeApi.interceptors.response.use(
  (response) => ({ ...response, data: { ...response.data.data } }),
  (error) => {
    Promise.reject(error);
  },
);

/**
 * Create a query client for the `react-query`'s QueryClientProvider
 *
 * See: https://react-query.tanstack.com/reference/QueryClient
 */
export const queryClient = new QueryClient();
