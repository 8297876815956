import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise } from 'axios';
import Cookies from 'js-cookie';
import { RefObject } from 'react';
import { StoreApi } from 'utils/api/instances';
import { API_ROOT, STORE_ROOT } from 'utils/constants/urls';
import { showFlash } from 'utils/domHelpers';
import { formatPrice, logError } from 'utils/helpers';
import { getStore, addDataToStore } from 'utils/helpers/localStorageHelpers';
import {
  LineItem,
  AxiosErrorResponse,
  Order,
  CreditRedemptionRequest,
} from 'utils/types';
import { v4 as uuidv4 } from 'uuid';

import { CookieNames } from './constants/cookies';
import { SHIPPING } from './constants/shipping';
import { trackJustUno } from './vendor/justuno';

declare global {
  interface Window {
    apiRoot: string;
    juapp: any;
  }
}

const ORDER_KEY = 'current_order';

const IP_API_URL = 'https://api.ipify.org/';

let onCartTrigger: CustomEvent | undefined;

const CART_EVENTS_NAMES = {
  loading: 'loading',
  setShippingMethod: 'setShippingMethod',
};

const CART_EVENTS: Record<string, CustomEvent> = {};

if (typeof window !== 'undefined') {
  onCartTrigger = new CustomEvent('onCartTrigger');

  CART_EVENTS.startLoading = new CustomEvent(CART_EVENTS_NAMES.loading, {
    detail: { isLoading: true },
  });
  CART_EVENTS.stopLoading = new CustomEvent(CART_EVENTS_NAMES.loading, {
    detail: { isLoading: false },
  });

  window.addEventListener('onCartTrigger', (event: Event) => {
    const customEvent = event as CustomEvent;

    if (customEvent && customEvent.detail) {
      const {
        detail: {
          error: { type },
        },
      } = customEvent;

      // When a cartError is triggered, stop the cart loader
      if (type === 'cartError') {
        window.dispatchEvent(CART_EVENTS.stopLoading);
      }
    }
  });
}
/**
 * Handle axios error responses.
 * These will return either an array of strings, or just a string.
 * Always return an array for clean UI handling.
 */
const getResponseErrors = (err: AxiosErrorResponse): string[] | undefined => {
  const error = err.response && err.response.data && err.response.data.errors;
  if (typeof error === 'string') {
    return [error];
  }

  if (Array.isArray(error)) {
    return error;
  }

  if (typeof error === 'object') {
    return Object.keys(error).map((key) => {
      const errorStr = Array.isArray(error[key])
        ? // @ts-ignore
          error[key].join(',')
        : error[key];

      // Not using this for now, but could in the future.
      // This is for prop: errorMsg format. Instead for now we'll use just errorMsg
      // -Jeron 10/18/20
      // const capKey = key.charAt(0).toUpperCase() + key.slice(1);
      // return `${capKey}: ${errorStr}`;

      return errorStr;
    });
  }

  return undefined;
};

/**
 * Entire order data including line items, discount, subtotal, promotions
 */
const getCurrentOrder = (): any => {
  const storeRaw = getStore();
  const { current_order } = storeRaw ?? {};
  return current_order ? JSON.parse(current_order) : {};
};

const getLineItems = () => {
  const currentOrderRaw = getCurrentOrder();
  const { line_items } = currentOrderRaw;
  return line_items || [];
};

const getCartCount = (): number =>
  getLineItems().reduce(
    (acc: number, item: { quantity: number }) => acc + item.quantity,
    0,
  );

export const getUserIp = async () => {
  if (Cookies.get(CookieNames.UserIp)) return Cookies.get(CookieNames.UserIp);

  try {
    const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    const response = await fetch(IP_API_URL);
    const userIp = await response.text();
    if (ipRegex.test(CookieNames.UserIp))
      Cookies.set(CookieNames.UserIp, userIp);
    return userIp;
  } catch (error) {
    logError('error retrieving user IP address', {
      component: 'cartHelpers',
      function: 'getUserIp',
      stackTrace: error,
    });
  }
};

const getSession = async (
  viewEventId?: string,
  viewProductId?: number,
): Promise<AxiosPromise | undefined> => {
  if (typeof window !== 'undefined') {
    // EventId param needed for Facebook Conversions API
    // currently only used for tracking PDP page views
    let eventParams = '';
    if (viewProductId) {
      const userIp = await getUserIp();
      eventParams = `&eventId=${viewEventId}&productId=${viewProductId}&userIp=${userIp}`;
    }
    const requestOpts: AxiosRequestConfig = {
      method: 'GET',
      url: `${
        // @ts-ignore
        window.storeRoot || STORE_ROOT
      }/sessions/status.json?location=${window.location}${eventParams}`,
      withCredentials: true,
    };

    return axios(requestOpts);
  }
};

const setShippingMethodId = (shippingMethodId: string): void => {
  addDataToStore({ shipping_method_id: shippingMethodId });

  const event = new CustomEvent(CART_EVENTS_NAMES.setShippingMethod, {
    detail: { shippingMethodId },
  });
  window.dispatchEvent(event);
};

const getShippingMethodId = (): string => getStore()['shipping_method_id'];

const clearShippingMethodId = (): void =>
  setShippingMethodId(SHIPPING.STANDARD);

const getShippingMethodInfo = (
  shippingMethodId: string | number,
): {
  shippingType: string;
  shippingCost: number;
} => {
  const shippingType =
    shippingMethodId === 10 || shippingMethodId === '10'
      ? 'Express'
      : 'Standard';

  const shippingCost = shippingType === 'Express' ? SHIPPING.EXPRESS_FEE : 0;

  return {
    shippingType,
    shippingCost,
  };
};

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const isAuthTokenExpired = (token: string) => {
  if (!token) return true;
  const parsedJwt = parseJwt(token);
  // returned in seconds
  if (parsedJwt.exp) {
    const now = new Date().getTime();
    const nowInSeconds = now / 1000;
    const expInSeconds = parsedJwt.exp;
    if (nowInSeconds > expInSeconds) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const getAuthToken = async () => {
  const store = getStore();

  const authToken = store.auth_token;
  const loggedIn = store.logged_in;

  // if user is logged in and has authToken, return that token even if expired
  if (authToken && loggedIn) {
    return authToken;
  }

  const authTokenExpired = isAuthTokenExpired(authToken);

  // if the authToken hasn't expired and user is not logged in, return it
  if (authToken && !authTokenExpired) {
    return authToken;
  }
  try {
    deleteOrderFromStore();
    const sessionResponse = await getSession();
    sessionResponse && addDataToStore(sessionResponse.data);
    // store will update with session, so we need to retrieve it again
    return getStore().auth_token;
  } catch (error) {
    logError('error in getAuthToken', {
      component: 'cartHelpers',
      function: 'getAuthToken',
      stackTrace: error,
    });
    throw error;
  }
};

const initOrderData = (): any => {
  const store = getStore();

  if (store.logged_in) {
    return { user_id: store.user_id };
  }

  return { token: store.token };
};

const createOrder = async (): Promise<any> => {
  if (typeof window !== 'undefined') {
    try {
      await getUser();
      const authToken = await getAuthToken();
      const requestOpts: AxiosRequestConfig = {
        data: initOrderData(),
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        url: `${
          // @ts-ignore
          window.apiRoot || API_ROOT
        }/v2/orders/?location=${window.location}`,
      };
      return StoreApi(requestOpts);
    } catch (error) {
      resetSession();

      const errors = getResponseErrors(error as AxiosErrorResponse);
      if (errors) {
        const event = new CustomEvent('onCartTrigger', {
          detail: { error: { type: 'cartError', errors } },
        });

        window.dispatchEvent(event);
      }
      return Promise.resolve();
    }
  }
};

export const saveOrder = (
  order: AxiosResponse | undefined,
  key: string = ORDER_KEY,
): void => {
  const store = getStore();

  //if the cart is empty clear the shipping method
  if (order?.data?.line_items?.length === 0) {
    clearShippingMethodId();
  }

  store[key] = JSON.stringify(order?.data);
  localStorage.setItem('customStorage', JSON.stringify(store));
  Cookies.set('customStorage', JSON.stringify(store));
};

const getOrder = async (key: string = ORDER_KEY): Promise<string> => {
  const order = getStore()[key];

  // Create a new order if user has logged in since order was created
  // and the existing order is empty. Allows for adding membership products.
  const staleGuestOrder =
    order?.line_items?.length === 0 && !order.user_id && (await isLoggedIn());

  if (order && !staleGuestOrder) return Promise.resolve(order);

  try {
    // no order was found, so we're making a new one
    const orderResponse = await createOrder();
    orderResponse && saveOrder(orderResponse);

    // store will update with order, so we need to retrieve it again
    return Promise.resolve(getStore()[key]);
  } catch (error) {
    resetSession();

    const errors = getResponseErrors(error as AxiosErrorResponse);
    if (errors) {
      const event = new CustomEvent('onCartTrigger', {
        detail: { error: { type: 'cartError', errors } },
      });

      window.dispatchEvent(event);
    }
    return Promise.resolve(`error in getOrder: ${error}`);
  }
};

// TODO: need to add new error handling here too?
const updateOrderAndGoToCart = async (retry?: boolean): Promise<any> => {
  if (typeof window !== 'undefined') {
    let orderUrl: string;
    try {
      const orderId = JSON.parse(await getOrder()).id;
      const authToken = await getAuthToken();
      orderUrl = `${window.apiRoot || API_ROOT}/v2/orders/${orderId}`;

      const requestOpts: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'GET',
        url: orderUrl,
      };

      await StoreApi(requestOpts)
        .then((resp: AxiosResponse) => {
          saveOrder(resp);
        })
        .then(() => {
          if (onCartTrigger) {
            window.dispatchEvent(onCartTrigger);
          }
        })
        .catch((error) => {
          // if updating order errors, reset session and try again
          if (orderUrl === error.config?.url && !retry) {
            const errors = getResponseErrors(error);
            if (errors) {
              const event = new CustomEvent('onCartTrigger', {
                detail: { error: { type: 'cartError', errors } },
              });

              window.dispatchEvent(event);
            }
            resetSession();
            updateOrderAndGoToCart(true);
          }
        });
    } catch (error) {
      const errors = getResponseErrors(error as AxiosErrorResponse);
      if (errors) {
        const event = new CustomEvent('onCartTrigger', {
          detail: { error: { type: 'cartError', errors } },
        });

        window.dispatchEvent(event);
      }
    }
  }
};

const resetSession = (): void => {
  localStorage.setItem('customStorage', '{}');
  Cookies.set('customStorage', '{}');
};

/**
 * @deprecated - Replaced by useCart hook
 */
const addLineItem = async (
  data: LineItem,
  retry?: boolean,
  eventId?: string,
  callback?: () => void,
): Promise<void> => {
  if (typeof window !== 'undefined') {
    let lineItemUrl;

    try {
      const orderRaw = await getOrder();
      const myOrder = JSON.parse(orderRaw);
      const orderId = myOrder.id;
      const authToken = await getAuthToken();

      const userIp = await getUserIp();

      const eventData = {
        event_id: eventId || uuidv4(),
        user_ip: userIp,
      };

      // If local, set apiRoot to http://localhost:3000/aapi
      lineItemUrl = `${
        // @ts-ignore
        window.apiRoot || API_ROOT
      }/v1/orders/${orderId}/line_items?location=${window.location}`;

      const requestOpts: AxiosRequestConfig = {
        data: { ...data, ...eventData },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        url: lineItemUrl,
      };

      const response = await StoreApi(requestOpts);
      trackJustUnoCart(response.data, data.quantity);
      return updateOrderAndGoToCart();
    } catch (error) {
      const errors = getResponseErrors(error as AxiosErrorResponse);

      if (errors) {
        const event = new CustomEvent('onCartTrigger', {
          detail: { error: { type: 'cartError', errors } },
        });

        window.dispatchEvent(event);
      }

      if (
        lineItemUrl === (error as AxiosResponse).config?.url &&
        !retry &&
        (error as AxiosErrorResponse).response?.data.status === 401
      ) {
        resetSession();
      }

      if ((error as AxiosErrorResponse).response?.data.status === 404) {
        deleteOrderFromStore(true, callback);
      }

      return Promise.reject(errors);
    }
  }
};

export const trackJustUnoCart = (data: LineItem, quantity: number): void => {
  trackJustUno('cartItemAdd', {
    productid: data.product_id,
    variationid: data.variant_id,
    quantity: quantity,
    price: data.amount,
  });
};

const deleteOrderFromStore = (
  closeCartAndShowError?: boolean,
  callback?: () => void,
): void => {
  const store = getStore();
  delete store.current_order;
  localStorage.setItem('customStorage', JSON.stringify(store));
  Cookies.set('customStorage', JSON.stringify(store));
  clearShippingMethodId();

  if (closeCartAndShowError) {
    showFlash(
      'alert-error',
      'Failed to locate your previous order. Sorry for the inconvenience!',
    );
    setCartOpen(false);
    window.dispatchEvent(CART_EVENTS.stopLoading);
    callback && callback();
  }
};

// interacts with the CartContainer to open/close the cart pullout
const setCartOpen = (open?: boolean): void => {
  const event = new CustomEvent('onSetCartOpen', {
    detail: { open },
  });
  window.dispatchEvent(event);
};

/**
 * @deprecated - Replaced by react-query hooks inside of Cart component
 */
const editLineItem = async (item: LineItem, retry?: boolean): Promise<void> => {
  if (typeof window !== 'undefined') {
    let lineItemUrl;

    window.dispatchEvent(CART_EVENTS.startLoading);

    try {
      lineItemUrl = `${
        // @ts-ignore
        window.apiRoot || API_ROOT
      }/v1/orders/${item.order_id}/line_items?location=${window.location}`;
      const authToken = await getAuthToken();

      const itemData = {
        variant_id: item.variant_id,
        quantity: item.quantity,
        total_quantity: item.quantity,
      };

      const requestOpts: AxiosRequestConfig = {
        data: itemData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        url: lineItemUrl,
      };

      await StoreApi(requestOpts);
      await updateOrderAndGoToCart();
    } catch (error) {
      const errors = getResponseErrors(error as AxiosErrorResponse);

      if (errors) {
        const event = new CustomEvent('onCartTrigger', {
          detail: { error: { type: 'cartError', errors } },
        });

        window.dispatchEvent(event);
      }

      if (lineItemUrl === (error as AxiosResponse).config?.url && !retry) {
        resetSession();
      }

      if ((error as AxiosErrorResponse).response?.data.status === 404) {
        deleteOrderFromStore(true);
      }
    } finally {
      window.dispatchEvent(CART_EVENTS.stopLoading);
    }
  }
};

// TODO: finish line item removal
const removeLineItem = async (
  item: LineItem,
  retry?: boolean,
): Promise<void> => {
  if (typeof window !== 'undefined') {
    const orderId = item.order_id || getStore().current_order;
    let lineItemUrl: string | null = null;
    window.dispatchEvent(CART_EVENTS.startLoading);

    try {
      lineItemUrl = `${
        // @ts-ignore
        window.apiRoot || API_ROOT
      }/v1/orders/${orderId}/line_items/${item.id}?location=${window.location}`;
      const authToken = await getAuthToken();

      const requestOpts: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'DELETE',
        url: lineItemUrl,
      };

      await StoreApi(requestOpts);
      await updateOrderAndGoToCart();
    } catch (error) {
      const errors = getResponseErrors(error as AxiosErrorResponse);

      if (errors) {
        const event = new CustomEvent('onCartTrigger', {
          detail: { error: { type: 'cartError', errors } },
        });

        window.dispatchEvent(event);
      }

      if (lineItemUrl === (error as AxiosResponse).config?.url && !retry) {
        resetSession();
      }

      if ((error as AxiosErrorResponse).response?.data.status === 404) {
        deleteOrderFromStore(true);
      }
    } finally {
      window.dispatchEvent(CART_EVENTS.stopLoading);
    }
  }
};

// TODO finish retrieving the product for removal
const removeProduct = async (variantId: number) => {
  const lineItems = getLineItems();
  const index = lineItems.findIndex(
    (p: LineItem) => p.variant_id === variantId,
  );

  if (index >= 0) {
    await removeLineItem(lineItems[index]);
  }
};

/**
 * @deprecated - Replaced by react-query hooks inside of Cart component
 */
const upgradeLineItem = async (
  variantId: number,
  upgradedVariantId: number,
  button?: RefObject<HTMLDivElement>,
  callback?: () => void,
): Promise<void> => {
  if (button?.current?.getAttribute('disabled')) return;
  button?.current?.setAttribute('disabled', 't');
  window.dispatchEvent(CART_EVENTS.startLoading);

  try {
    const lineItem = getLineItems().find(
      (li: LineItem) => li.variant_id === variantId,
    );
    if (!lineItem) throw new Error('No upgradeable line item available.');

    const data = {
      quantity: lineItem?.quantity || 1,
      variant_id: upgradedVariantId,
    };

    // Remove old line item and only proceed to add new if successful
    await removeProduct(variantId);
    await addLineItem(data);
    callback && callback();
  } catch (error) {
    logError('error in upgradeLineItem', {
      component: 'cartHelpers',
      function: 'upgradeLineItem',
      stackTrace: error,
    });
    const errMsg =
      'Sorry, there was an error upgrading this item. Please try again.';
    const event = new CustomEvent('onCartTrigger', {
      detail: { error: { type: 'cartError', errMsg } },
    });
    window.dispatchEvent(event);
  } finally {
    button?.current?.removeAttribute('disabled');
    window.dispatchEvent(CART_EVENTS.stopLoading);
  }
};

const addMembershipToOrder = async (variantId: number): Promise<void> => {
  const data = {
    quantity: 1,
    variant_id: variantId,
  };

  return addLineItem(data);
};

const redeemCredit = async (data: any) => {
  let creditRedemptionUrl;
  try {
    const authToken = await getAuthToken();
    creditRedemptionUrl = `${API_ROOT}/v1/credits/redeem`;

    const requestOpts: AxiosRequestConfig = {
      data: data,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'POST',
      url: creditRedemptionUrl,
    };

    const result = await axios(requestOpts);
    return result;
  } catch (error) {
    logError('there was an issue redeeming this credit', {
      component: 'cartHelpers',
      function: 'redeemCredit',
      stackTrace: error,
    });
    return { data: null };
  }
};

const orderMembershipKit = async (
  variantId: number,
  userId: number,
): Promise<Order> => {
  const redeemParams: CreditRedemptionRequest = {
    user_id: userId,
    variant_id: variantId,
  };
  try {
    const { data } = await redeemCredit(redeemParams);
    return data;
  } catch (err) {
    logError('there was an issue redeeming credit', {
      component: 'cartHelpers',
      function: 'orderMembershipKit',
      stackTrace: err,
    });
    throw err;
  }
};

const getUser = async (viewEventId?: string, viewProductId?: number) => {
  try {
    const sessionResponse = await getSession(viewEventId, viewProductId);
    sessionResponse && addDataToStore(sessionResponse.data);
    return sessionResponse;
  } catch (error) {
    logError('there was an issue getting user session data', {
      component: 'cartHelpers',
      function: 'getUser',
      stackTrace: error,
    });
    throw error;
  }
};

const currentOrderGrandTotalInCents = (currentOrderGrandTotal: number) =>
  Math.round(parseFloat(formatPrice(currentOrderGrandTotal)) * 100);

const addToCartDataLayer = (
  productId: number,
  name: string,
  price: string,
  eventId: string,
): void => {
  window &&
    window.dataLayer.push({
      event: 'addToCart',
      eventId: eventId,
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            {
              id: productId,
              name: name,
              price: price,
              is_subscription: 'false',
              brand: '',
              category: '',
              variant: '',
              quantity: 1,
            },
          ],
        },
      },
    });
};

/**
 * Is a user currently logged in?
 *
 * @returns Promise resolving to true if a user is logged in
 */
const isLoggedIn = async (): Promise<boolean> => {
  const data = await getUser();
  const sessionData = data ? data.data : {};
  return Promise.resolve(sessionData.logged_in || false);
};

export {
  addLineItem,
  CART_EVENTS_NAMES,
  editLineItem,
  upgradeLineItem,
  removeLineItem,
  removeProduct,
  getCartCount,
  getCurrentOrder,
  getLineItems,
  getAuthToken,
  getOrder,
  orderMembershipKit,
  getUser,
  getResponseErrors,
  addMembershipToOrder,
  currentOrderGrandTotalInCents,
  addToCartDataLayer,
  resetSession,
  deleteOrderFromStore,
  isLoggedIn,
  setShippingMethodId,
  getShippingMethodId,
  getShippingMethodInfo,
  clearShippingMethodId,
};
