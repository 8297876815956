let API_ROOT: string,
  APP_ROOT: string,
  STORE_ROOT: string,
  RESULTS_ROOT: string;

const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  QA: 'qa',
  PREVIEW: 'preview',
  DEV: 'dev',
  LOCAL: 'local',
  TEST: 'test',
};

switch (process.env.REACT_APP_ENV) {
  case ENVIRONMENTS.PRODUCTION:
    API_ROOT = 'https://secure.everlywell.com/aapi';
    APP_ROOT = 'https://www.everlywell.com';
    STORE_ROOT = 'https://secure.everlywell.com';
    RESULTS_ROOT = 'https://results.everlywell.com';
    break;
  case ENVIRONMENTS.STAGING:
    API_ROOT = 'https://secure-staging.everlywell.com/aapi';
    APP_ROOT = 'https://staging.everlywell.com';
    STORE_ROOT = 'https://secure-staging.everlywell.com';
    RESULTS_ROOT = 'https://results-staging.everlywell.com';
    break;
  case ENVIRONMENTS.PREVIEW:
    API_ROOT = 'https://www-staging-backend-api.secure-gitlab-preview.everlywell.com/aapi';
    APP_ROOT = 'https://www-staging-backend-api.public-preview.everlywell.com';
    STORE_ROOT = 'https://www-staging-backend-api.secure-gitlab-preview.everlywell.com';
    RESULTS_ROOT = 'https://www-staging-backend-api.results-preview.everlywell.com';
    break;
  case ENVIRONMENTS.QA:
    API_ROOT = 'https://secure-qa.everlywell.com/aapi';
    APP_ROOT = 'https://qa.everlywell.com';
    STORE_ROOT = 'https://secure-qa.everlywell.com';
    RESULTS_ROOT = 'https://results-qa.everlywell.com';
    break;
  case ENVIRONMENTS.DEV:
    API_ROOT = 'https://secure-dev.everlywell.com/aapi';
    APP_ROOT = 'https://dev.everlywell.com';
    STORE_ROOT = 'https://secure-dev.everlywell.com';
    RESULTS_ROOT = 'https://results-dev.everlywell.com';
    break;
  case ENVIRONMENTS.LOCAL:
    API_ROOT = process.env.API_ROOT || 'http://localhost:3000/aapi';
    APP_ROOT = process.env.PUBLIC_APP_ROOT || 'http://localhost:8080';
    STORE_ROOT = process.env.API_ROOT || 'http://localhost:3000';
    RESULTS_ROOT = 'http://localhost:3001';
    break;
  default:
    API_ROOT = 'https://secure-qa.everlywell.com/aapi';
    APP_ROOT = 'http://localhost:8080';
    STORE_ROOT = 'https://secure-qa.everlywell.com';
    RESULTS_ROOT = 'https://results-qa.everlywell.com';
}

const CONTENTFUL_ROOT = 'https://cdn.contentful.com:443';

if (process.env.NODE_ENV === ENVIRONMENTS.TEST) {
  API_ROOT = 'https://www.everlywell.com/aapi';
  APP_ROOT = 'https://www.everlywell.com';
  STORE_ROOT = 'https://www.everlywell.com';
  RESULTS_ROOT = 'https://results.everlywell.com';
}

export {
  API_ROOT,
  APP_ROOT,
  STORE_ROOT,
  CONTENTFUL_ROOT,
  RESULTS_ROOT,
  ENVIRONMENTS,
};
