import './src/fonts/ew-font.css';

export const onClientEntry = () => {
  // IntersectionObserver polyfill
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};

export { wrapRootElement } from './gatsby-shared';
