import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { useQuery } from 'react-query';
import ewAnalytics from 'utils/analytics';
import { getUserSession } from 'utils/api/sessionApis';
import { addDataToStore } from 'utils/helpers/localStorageHelpers';

import { logError } from '../../helpers';
import { defaultUser } from './context';

export const useUserContext = () => {
  const ldClient = useLDClient();

  const {
    data: sessionResponse,
    isError,
    refetch,
  } = useQuery(`user-session`, () => getUserSession(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: (response) => {
      addDataToStore(response.data as any);

      ewAnalytics.setUserInfo({
        loggedIn: response.data.logged_in,
        isMember: Boolean(response.data.is_member),
        id: response.data.user_id ?? undefined,
      });

      ldClient?.identify({
        key: 'guest',
        anonymous: true,
      });
    },
    onError: () => {
      ldClient?.identify({
        key: 'guest',
        anonymous: true,
      });
    },
  });

  let userData = defaultUser;

  if (sessionResponse?.data) {
    userData = {
      loggedIn: sessionResponse.data.logged_in,
      isMember: Boolean(sessionResponse.data.is_member),
      membershipType: sessionResponse.data.membership_type,
      activeCredits: sessionResponse.data.active_credits ?? 0,
      userId: sessionResponse.data.user_id,
      userEmail: sessionResponse.data.user_email,
      nextCreditDate: sessionResponse.data.next_credit_date ?? undefined,
      firstName: sessionResponse.data.first_name,
      lastName: sessionResponse.data.last_name,
      membershipState: sessionResponse.data.membership_state ?? undefined,
      fetched: true,
    };
  } else if (isError) {
    userData = { ...defaultUser, fetched: true };
  }

  const runEffect = async () => {
    try {
      await refetch();
    } catch (err: any) {
      logError(err, undefined);
    }
  };

  return {
    userData,
    /**
     * @deprecated - no longer need to manually retrigger the query
     */
    runEffect,
  };
};

export default useUserContext;
