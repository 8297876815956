export const ANALYTICS = {
  CATEGORIES: {
    NAVIGATION: 'Navigation',
    NOTIFICATION: 'Notification',
    MOBILE_APP: 'Mobile app on-ramp',
  },
  EVENTS: {
    PURCHASE: 'Purchase',
    PURCHASE_ERROR: 'Purchase Error',
    CLICKED_BUTTON: 'Clicked Button',
    CLICKED_MODAL: 'Clicked Modal Open',
    CLICKED_LINK: 'Clicked Link',
    SWIPE: 'Slider Swipe Gesture',
    ADD_TO_CART: 'Add To Cart',
    ADD_TO_CART_ERROR: 'Error: Add To Cart',
    VIEWED_PAGE: 'Viewed Page',
    VIEWED_COMPONENT: 'Viewed Component',
    CONTROL_VIEWED_PAGE: 'Control Pilot - Viewed Page',
    CURRENT_VIEWED_PAGE: 'Current Pilot - Viewed Page',
    CONTROL_ENROLL_BUTTON_CLICK: 'Control Pilot - Enroll Button Click',
    CONTROL_ALREADY_ENROLLED: 'Control Pilot - Already Enrolled',
    STI_VIEWED_PAGE: 'STI Pilot - Viewed Page',
    STI_CLICKED_BUTTON: 'STI Pilot - Clicked Button',
    MEMBERSHIP_CLICKED_BUTTON: 'Membership Pilot - Clicked Product Button',
    STI_ENROLL_BUTTON_CLICK: 'STI Pilot - Enroll Button Click',
    STI_ALREADY_ENROLLED: 'STI Pilot - Already Enrolled',
    SCROLLED_TO_BOTTOM: 'Scrolled to bottom of page',
    RESTRICTED_STATE_ERROR: 'Restricted State Error',
    QUANTITY_SELECT: 'Quantity Changed',
    SUBSCRIPTION_SELECT: 'Subscription Selected',
    CHANGED_FREQUENCY: 'Changed Subscription Frequency',
    CHANGE_FREQUENCY_CLICKED: 'Clicked Change Frequency',
    PURCHASE_TYPE: 'Changed Purchase Type',
    OPEN_REVIEWS_MODAL: 'Opened Reviews Modal',
    FAQ_CLICK: 'Opened FAQ',
    HERO_SLIDE: 'PDP Hero Slide',
    HERO_SWIPE: 'PDP Hero Swipe',
    HERO_PREV_ARROW_CLICK: 'PDP Hero Previous Arrow Click',
    HERO_NEXT_ARROW_CLICK: 'PDP Hero Next Arrow Click',
    HERO_BULLET_CLICK: 'PDP Hero Bullet Click',
    CHECKOUT_VALIDATION_ERROR: 'Error: Checkout Validation',
    CHECKOUT_PROCESSING_ERROR: 'Error: Processing Order',
    CHECKOUT_COMPLETING_ERROR: 'Error: Completing Order',
    EXPRESS_PAY: 'Completed Express Pay Flow',
    EXPRESS_PAY_FAILED: 'Failed to Complete Express Pay Flow',
    NOTIFICATION: 'Notification Shown',
    CLEAR_NOTIFICATION: 'Notification Cleared',
    API_FAILURE: 'API Failure',
    API_SUCCESS: 'API Success',
    FORM_INTERACTION: 'Begin Form Interaction',
    VIEWED_ORDER_MODAL: 'Viewed Order modal',
    CLICKED_PSC_LOCATION: 'Clicked PSC Location',
    CHECKBOX_CLICKED: 'Checkbox Clicked',
    SHIPPING_METHOD_SELECTED: 'Shipping Method Selected',
    SMS_SUBSCRIPTION: 'SMS Subscription',
    SLIDE_CHANGE: 'Slide Change',
    PRODUCT_CATEGORY_CLICK: 'Product Category Click',
    HOW_IT_WORKS_TAB_CLICK: 'How It Works Tab Click',
    PRE_PURCHASE_SCREENER_ANSWER: 'Pre Purchase Screener Answer',
    DROPDOWN_SELECTION: 'Dropdown Selection',
    CLICKED_PROMO_CARD: 'Clicked Promo Card',
  },
  LABELS: {
    ADD_TO_CART: 'Add to Cart',
    BREADCRUMB: 'Breadcrumb',
    LEARN_MORE: 'Learn More',
    VIEWED_TEST_MARKERS: 'Viewed Test Markers',
    FS_TO_FSC_CART_UPGRADE: 'FSC Cart Upgrade',
    HOMEPAGE_HERO_BANNER: 'Homepage Hero Banner',
    HOMEPAGE_HERO_CTA: 'Homepage Hero CTA',
    HOMEPAGE_HERO_CATEGORY: 'Homepage Hero Category',
    CATEGORY_PAGE_HERO_LINK: 'Category Page Hero Link',
    HOMEPAGE_COVID_CTA: 'Homepage COVID CTA',
    HOMEPAGE_POPULAR_ALL_PRODUCTS_CTA: 'Homepage Popular All Products CTA',
    PDP_MORE_TESTS_CTA: 'PDP MoreTests CTA',
    HOMEPAGE_POPULAR_PRODUCT_CARD: 'Homepage Popular Product Card',
    PDP_POPULAR_PRODUCT_CARD: 'PDP Popular Product Card',
    HOMEPAGE_FOR_BUSINESS_CTA: 'Homepage For Business CTA',
    HOMEPAGE: 'Homepage',
    DOWNLOAD_APP_PAGE: 'Mobile app on-ramp',
    DOWNLOAD_APP: 'Download App',
    APP_STORE: 'App Store CTA in Footer',
    PROCEED_WEB: 'Proceed to web',
    PDP: 'PDP',
    PIP_PRODUCT_CARD: 'Product Index Page (PIP) Product Card',
    PIP_CATEGORY_FILTER: 'PIP CATEGORY FILTER',
    PIP_ADD_TO_CART_ICON_BUTTON: 'PIP Product Card Add to Cart Icon Button',
    PIP_ADD_TO_CART_BUTTON: 'PIP Product Card Add to Cart Button',
    EXPRESS_PAY_PDP: 'PDP Express Pay Purchase',
    EXPRESS_PAY_CART: 'DLS Cart Express Pay Purchase',
    EXPRESS_PAY_CHECKOUT: 'Checkout Express Pay',
    EXPRESS_PAY_CANCEL: 'Cancel Express Pay',
    ADD_TO_CART_MODAL: 'Add to Cart via Modal',
    ADD_TO_CART_UPGRADE_MODAL_NO_UPGRADE:
      'Add Non-upgraded Item to Cart via Upgrade Modal',
    ADD_TO_CART_UPGRADE_MODAL_WITH_UPGRADE:
      'Add Upgraded Item to Cart via Upgrade Modal',
    PDP_UPGRADE_MODAL: 'PDP Upgrade Modal',
    PDP_COMPARISON_MODAL:
      'FS & FSC Combined Page - Bullet Points and Radio Buttons - Comparison Modal',
    PDP_COMPARISON_MODAL_FROM_DESCRIPTION:
      'FS & FSC Combined Page - PDP Description - Comparison Modal',
    ADD_TO_CART_PDP_PRODUCT_CARD: 'Add to Cart via PDP Product Card',
    NO_COVID_SYMPTOMS: 'No COVID-19 symptoms',
    MILD_COVID_SYMPTOMS: 'Mild COVID-19 symptoms',
    CHECKOUT: 'Checkout',
    SEVERE_COVID_SYMPTOMS: 'Severe COVID-19 symptoms',
    NO_COVID_EXPOSURE: 'No COVID-19 exposure',
    POTENTIAL_COVID_EXPOSURE: 'COVID-19 city exposure',
    SUSPECTED_COVID_EXPOSURE: 'COVID-19 close to undiagnosed',
    KNOWN_COVID_EXPOSURE: 'COVID-19 close to diagnosed',
    INTAKE_SYMPTOMS_COVID_SCREENER: 'COVID-19 Intake Symptoms',
    INTAKE_EXPOSURE_COVID_SCREENER: 'COVID-19 Intake Exposure',
    NOT_RECOMMENDED_COVID_SCREENER: 'COVID-19 Not recommended',
    SEEK_MEDICAL_CARE_COVID_SCREENER: 'COVID-19 Seek medical care',
    INTAKE_COVID_CHECKOUT: '/covid-checkout',
    MEMBERSHIP_LOGGED_IN: 'Logged-in Membership Page',
    PLACE_ORDER: 'Place Order',
    APPLY_PROMO: 'Apply Promo',
    APPLY_GIFTCARD: 'Apply Gift Card',
    PAYPAL_PURCHASE: 'Checkout with Paypal',
    NOTIFICATION: 'Clear Notification',
    PRODUCT_TILE_EXPAND: 'Product Tile - Expand',
    PRODUCT_TILE_COLLAPSE: 'Product Tile - Collapse',
    PRODUCT_TILE_SEE_MORE: 'Product Tile - See More',
    PRODUCT_TILE_SEE_LESS: 'Product Tile - See Less',
    SHIPPING_INFORMATION: 'Shipping Information',
    TEST_HISTORY: 'Membership Tests History',
    TEST_HISTORY_VIEW_RESULTS: 'Membership Tests History - View results',
    TEST_HISTORY_REDEEM_TEST: 'Membership Tests History - Redeem Test',
    LAB_SEARCH_BY_ZIPCODE: 'Search for nearest lab by zipcode',
    SELECT_LAB_LOCATION: 'Select Lab Location',
    STANDARD_SHIPPING: 'Standard Shipping',
    EXPRESS_SHIPPING: 'Express Shipping',
    AGE_RESTRICTION_MODAL: 'Age Restriction Modal',
    PRE_PURCHASE_DISCLAIMER_CTA: 'Pre Purchase Disclaimer CTA Click',

    ORDER_MODAL: 'Membership Credit Redemption Modal - viewed modal',
    ORDER_MODAL_EDIT: {
      label: 'Membership Credit Redemption Modal - Edit',
      description: 'Editing address once modal is open',
    },
    ORDER_MODAL_SAVE: {
      label: 'Membership Credit Redemption Modal - Save',
      description: 'Save after editing address in modal',
    },
    ORDER_MODAL_CANCEL: {
      label: 'Membership Credit Redemption Modal - Cancel',
      description:
        'Shows before edits have been made, cancels “editing” functionality',
    },
    ORDER_MODAL_CONFIRM_ORDER: {
      label: 'Membership Credit Redemption Modal - Confirm Order',
      description: 'Clicking Order from Credit Confirmation Modal',
    },
    ORDER_MODAL_CLOSE: {
      label: 'Membership Credit Redemption Modal - Close Modal',
      description: 'Clicking “X” or clicking outside of modal',
    },
    MEMBERSHIP_INTAKE: {
      CONFIRMATION: 'Membership Intake - Confirmation',
      CONSUMER_CONFIRMATION: 'Membership Intake - Consumer Confirmation',
      HEALTH_PROFILE: 'Membership Intake - Health Profile',
      NEXT: {
        SIGN_UP: 'Membership Intake - Sign Up next button',
        HEALTH_PROFILE: 'Membership Intake - Health Profile next button',
        PAYMENT_INFO: 'Membership Intake - Payment information next button',
        TEST_RECOMMENDATION:
          'Membership Intake - Test Recommendation view all tests button',
      },
      NOT_FOUND: 'Membership Intake Not Found',
      PAGE: 'Membership Intake',
      PAYMENT_INFO: {
        COMPONENT: 'Membership Intake - Payment Information',
        EXPRESS_PAY: 'Membership Express Pay',
      },
      SELECTED_TEST: 'Membership Intake - Selected Test',
      SIGN_UP: 'Membership Intake - Sign Up',
      TESTS: 'Membership Intake - Tests Selection',
      TEST_RECOMMENDATION: 'Membership Intake - Test Recommendation',
      TEST_RECOMMENDATION_SELECTION:
        'Membership Intake - Test Recommendation Selection',
      WANTS_MARKETING: 'Wants Marketing',
    },
    CONTENT_RECOMMENDATION_SECTION: {
      label: 'Membership Support Content',
    },
    TEST_PLAN: {
      label: 'Membership Test Plan',
      claimed: 'Membership Test Plan - Claimed Tests',
      claim: 'Membership Test Plan - Redeem Test CTA',
      remove: 'Membership Test Plan - Remove Test CTA',
      upcoming: 'Membership Test Plan - Upcoming Tests',
      viewResults: 'Membership Test Plan - View Test Results CTA',
      add: {
        hero: 'Membership - Hero Section - Add to queue',
        testSelection: 'Membership - Test Selection - Add to queue',
        emptySelection: 'Membership - TestPlan - Add test to empty plan',
        featuredTest: 'Membership - TestPlan - Add test to empty plan',
      },
      api: {
        add: 'Membership - TestPlan - Test Added Success',
        remove: 'Membership - TestPlan - Test Removed Success',
      },
      redeem: {
        hero: 'Membership - Hero Section - Redeem Test',
        testPlanItem: 'Membership - Test Plan Item - Redeem Test',
      },
    },
    FEATURED_TESTS_SECTION: {
      label: 'Membership Featured Tests',
      swipe: 'Membership Featured Tests - Carousel Swipe Mobile Interaction',
      cta: 'Membership Featured Tests - CTA Button Interaction',
      nextSlide: 'Membership Featured Tests - Next Slide Button Interaction',
      prevSlide:
        'Membership Featured Tests - Previous Slide Button Interaction',
      viewedSlide: 'Membership Featured Tests - Viewed Slide',
    },
    CONSUMER: {
      label: 'Consumer',
    },
    PRIVACY_POLICY: 'Privacy Policy',
    SHOP_CATEGORIES: {
      13: 'Shop - Nutritional Health',
      6: "Shop - Women's Health",
      8: 'Shop - Sexual Health',
    },
  },
  PDP: {
    UNDERSTANDING_SECTION: 'Content Section - Understanding',
    HEALTH_PATH_SECTION: 'Content Section - Health Path',
    HOW_IT_WORKS_SECTION: 'Content Section - How It Works',
    DIGITAL_RESULTS_SECTION: 'Content Section - Digital Results',
    COMPARISON_TABLE: 'Content Section - Comparison Table',
  },
  HOMEPAGE: {
    HeroSlideChange: 'Hero Slide Change',
    HeroSlideClick: 'Hero Slide Click',
    PROGRAM_AD_CLICK: 'Homepage - Program Ad CTA Click',
  },
  CATEGORY_PAGE: {
    ADD_TO_CART: 'Add To Cart - Category Page',
    PRODUCT_CART_CLICK: 'Product Card Click - Category Page',
    CALLOUT_BANNER: 'Content Section - Callout Banner',
  },
};
