import React from 'react';
import { Script } from 'gatsby';
import { QueryClientProvider } from 'react-query';
import { UserContextProvider } from './src/utils/hooks/useUserContext/context';
import { queryClient } from './src/utils/api/instances';

import '@everlywell/leaves/lib/style.css';

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Script src="https://cdn.levelaccess.net/accessjs/YW1wMTMwOTk/access.js" />
        {element}
        <Script src="https://cdn.solvvy.com/deflect/customization/everlywell/solvvy.js" />
        <Script src="https://everlywell.extole.io/core.js" />
      </UserContextProvider>
    </QueryClientProvider>
  );
};
