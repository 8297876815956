/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CryptoES from 'crypto-es';
import { formatDistance } from 'date-fns';
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { PRODUCT_BADGES } from 'utils/constants/productBadges';
import { APP_ROOT, STORE_ROOT } from 'utils/constants/urls';
import { TestPlanItemAttribute } from 'utils/hooks/useTestPlan/types';
import {
  AllProductInfoType,
  ContentfulReview,
  IterableProduct,
  MembershipTypeOptions,
  Product,
  ProductBySlugType,
  ProductDataType,
  ProductsBySlugType,
  PromoDataResult,
  ProductVariants,
  Reviews,
  Variant,
  PageProductPromo,
  ProductBadgeConfig,
} from 'utils/types';
import { MembershipCategory } from 'utils/types/membershipSkus';

import { getValueByKey } from './helpers/membershipSkuHelper';

const logError = (error: string, context: object | undefined): void => {
  datadogLogs.logger.error(error, context);
};

const formatDate = (date: string): string =>
  new Date(`${date}T00:00:00`).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

const daysUntil = (date?: string): number | undefined => {
  if (!date) return;
  const then = new Date(date);
  const now = new Date();
  return Math.ceil((then.valueOf() - now.valueOf()) / (1000 * 3600 * 24));
};

const numericDate = (date: string): string =>
  new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });

const timeAgo = (date: string): string => {
  if (!date) return '';
  return (
    date &&
    formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    })
  );
};

const formatPrice = (price: number): string => {
  if (price) return price.toFixed(2);
  return '0.00';
};

// SHA1 hash a string, and convert to base64. We're using this for GTM tags as a marketing request.
// But SHA1 has been generally deprecated since 2010.
// If you need to use this for other purposes, you should consider a different hashing algorithm.
const hashSha1 = (rawMessage: string | undefined): string | undefined => {
  if (typeof rawMessage === 'string') {
    const hashRawMessage = CryptoES.SHA1(rawMessage);
    return CryptoES.enc.Base64.stringify(hashRawMessage);
  }
  return undefined;
};

const scrollToId = (id: string): null | void => {
  if (typeof window === `undefined`) return null;
  const headerHeight = 86;
  const el = document.getElementById(id);

  if (!el) return;

  // add polyfill to support smooth scrolling
  smoothscroll.polyfill();

  // scroll to element and make room for header
  window.scrollBy({
    behavior: 'smooth',
    top: el.offsetTop - window.pageYOffset - headerHeight,
  });
};

export const loadScrollToAnchor = (): void => {
  try {
    const hash = window.location.hash && window.location.hash.substring(1);
    if (hash) scrollToId(hash);
  } catch (err) {
    logError((err as Error).message, {
      method: 'loadScrollToAnchor',
    });
  }
};

const useClickOff = (onClose: (e: any) => void) => {
  const wrapperEl = useRef<HTMLDivElement>(null);

  // Solves the issue of tapping away in iOS to trigger the click event on the document
  useEffect(() => {
    document.body.style.cursor = 'pointer';

    return () => {
      document.body.style.cursor = 'default';
    };
  }, []);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!wrapperEl.current || !e.target) {
        return;
      }

      if (!wrapperEl.current.contains(e.target as Node)) {
        onClose(e);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClose]);

  return wrapperEl;
};

/**
 * Currently an open bug with body-scroll-lock on iPhone specifically iOS 12.x
 * if you touch at the bottom of the page when the browser controls ( bottom bar ) are hidden
 * you are still able to scroll the background
 *
 * when the controls down at the bottom are visible, the body-scroll-lock will work as intended
 *
 * the touchmove event isn't triggered at all down in the area of the bottom toolbar.
 * bug filed: https://bugs.webkit.org/show_bug.cgi?id=189586
 * github issue: https://github.com/willmcpo/body-scroll-lock/issues/82
 * */

const useDisableBodyScroll = (
  ref?: RefObject<HTMLDivElement>,
): RefObject<HTMLDivElement> => {
  let currRef = useRef<HTMLDivElement>(null);
  if (ref) currRef = ref;
  const wrapperEl = currRef;

  useEffect(() => {
    const node = wrapperEl.current;

    if (node) {
      disableBodyScroll(node, {
        reserveScrollBarGap: true,
      });
    }
    return () => {
      if (node) {
        enableBodyScroll(node);
      }
    };
  }, [wrapperEl]);

  return wrapperEl;
};

const useHasScrollableContent = (
  parentRef: RefObject<HTMLDivElement>,
): void | boolean => {
  const THRESHOLD = 20;
  const [hasScrollableContent, setHasScrollableContent] = useState(false);

  const handleScroll = (e: Event) => {
    if (!e.currentTarget) {
      return;
    }

    const target = e.currentTarget as HTMLDivElement;
    setHasScrollableContent(
      target.scrollHeight - target.scrollTop > target.clientHeight + THRESHOLD,
    );
  };

  useLayoutEffect(() => {
    const currentParent = parentRef.current; // to avoid the case where the current parent changes while in this effect
    if (!currentParent) {
      return;
    }

    currentParent.addEventListener('scroll', handleScroll);

    const { clientHeight, scrollHeight } = currentParent;

    if (scrollHeight > clientHeight + THRESHOLD) {
      setHasScrollableContent(true);
    }

    /* eslint-disable-next-line consistent-return */
    return () => {
      if (!currentParent) {
        return;
      }
      currentParent.removeEventListener('scroll', handleScroll);
    };
  }, [parentRef]);

  return hasScrollableContent;
};

const truncateString = (
  str: string,
  len: number,
  delimiter?: string,
  cap?: string,
): string => {
  if (str && str.length > len) {
    const lengthTrim = str.substr(0, len - 1);
    const endCap = cap || '...';
    const delim = delimiter || ' ';
    const finalString =
      lengthTrim.substr(
        0,
        Math.min(lengthTrim.length, lengthTrim.lastIndexOf(delim)),
      ) + endCap;
    return finalString;
  }

  return str;
};

const capitalize = (str: string): string => str[0].toUpperCase() + str.slice(1);

const formatForCurrency = (numberToFormat: number): string =>
  new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 0,
    style: 'currency',
  }).format(numberToFormat);

const generateKey: (length?: number) => string = (length = 8) =>
  Math.random().toString(16).substr(2, length);

const membershipIntakeUrl = (product_id: number): string => {
  try {
    const intakeUrl = `${APP_ROOT}/membership-intake?variantId=${product_id}`;

    return intakeUrl;
  } catch (err) {
    logError((err as Error)?.message, {
      method: 'membershipIntakeUrl',
    });
    return '';
  }
};

const loginUrl = (redirect: string = window.location.href): string => {
  try {
    const url = `${STORE_ROOT}/login?my_results_redirect=${encodeURIComponent(
      redirect,
    )}`;

    return url;
  } catch (err) {
    logError((err as Error)?.message, {
      method: 'loginUrl',
    });
    return '';
  }
};

const getMembershipTypeFromProduct = (
  membershipProduct: number,
): MembershipTypeOptions => {
  let membershipType: MembershipTypeOptions;
  switch (membershipProduct) {
    case 374:
      membershipType = MembershipTypeOptions.current;
      break;
    case 504:
    default:
      membershipType = MembershipTypeOptions.control;
      break;
  }
  return membershipType;
};

const getProductsBySlug = (
  nodes: ProductBySlugType[],
): Omit<ProductsBySlugType, 'reviewData'> => {
  const productsBySlug: Omit<ProductsBySlugType, 'reviewData'> = {};
  nodes
    .filter((node) => node.contentfulProductInfo !== null)
    .forEach((node) => {
      const variant = node.variants?.filter((variant) => variant.is_master)[0];
      // @ts-ignore
      productsBySlug[node.slug] = {
        slug: node.slug,
        name: node?.contentfulProductInfo?.displayName || '',
        ...(node?.contentfulProductInfo?.productCardImage && {
          boxImage: node?.contentfulProductInfo?.productCardImage,
        }),
        ...(node.contentfulProductInfo.boxImage && {
          cartImage: node.contentfulProductInfo.boxImage,
        }),
        productId: +node.productId,
        variantId: variant.id,
        price: variant.price,
      };
    });
  return productsBySlug;
};

const filterUniqueByCategory = (
  aryOfProducts: ProductDataType[],
  category: string,
): ProductDataType[] => {
  const seen: { [key: number]: number } = {};
  return aryOfProducts.filter((x) => {
    if (category === x.category && !seen[x.productId]) {
      seen[x.productId] = 1;
      return true;
    } else {
      return false;
    }
  });
};

const useEffectAsyncWrapper: (cb: () => void) => Promise<void> = async (cb) => {
  try {
    cb();
  } catch (e: any) {
    logError(e, undefined);
  }
};

const snakeToTitle = (str: string): string =>
  str.charAt(0).toUpperCase() +
  str
    .slice(1)
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', ' ').replace('_', ' '),
    );

const toKebabCase = (str: string): string =>
  str
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();

const trackIterable = (
  eventType: string,
  eventId: string | number,
  product: IterableProduct,
): void => {
  try {
    if (
      typeof window !== 'undefined' &&
      //hacky temporary solution until we fix Iterable events locally
      window.location.host !== 'localhost:8080'
    ) {
      window.dataLayer.push({
        event: eventType,
        eventId: eventId,
        product,
      });
    }
  } catch (err) {
    logError((err as Error).message, {
      method: 'trackIterable',
    });
  }
};

const findAllByKey = (obj: any, keyToFind: string): any => {
  try {
    return getValueByKey(obj, keyToFind);
  } catch (err) {
    logError((err as Error).message, {
      method: 'findAllByKey',
    });
  }
};

const getSkuKey = (
  item: Product | TestPlanItemAttribute | Variant,
  isVariant = false,
): string | void => {
  try {
    const memberShipTypeSubstr = item?.sku?.includes(
      MembershipTypeOptions.control,
    )
      ? 'control-membership-'
      : 'sti-membership-';
    const substr = !isVariant ? '-membership' : memberShipTypeSubstr;
    return item?.sku?.replace(substr, '') ?? '';
  } catch (err) {
    logError((err as Error).message, {
      method: 'getSkuKey',
    });
  }
};

const getVariantId = (memberShipCategory: MembershipCategory, sku: string) => {
  try {
    const variant = findAllByKey(memberShipCategory, sku || '')[0] ?? '';
    const variantId = variant?.variantInfo
      ? variant?.variantInfo[sku]
      : variant;

    if (!variantId) throw new Error('Variant not found');

    return variantId;
  } catch (err) {
    logError((err as Error).message, {
      method: 'getVariantId',
    });
  }
};

const getSpreeProductsBySlug = (
  productVariants: any,
): { [index: string]: any } =>
  Object.entries(productVariants).reduce(
    (acc: Record<string, any>, value): object => {
      const prodIdStr = value[0];
      const productData: any = value[1];
      acc[productData.slug] = {
        ...productData,
        productId: Number(prodIdStr),
      };
      return acc;
    },
    {},
  );

const getCombinedContenfulSpreeProduct = (
  contentfulProduct: any,
  spreeProductsBySlug: any,
  allReviews: ContentfulReview[],
) => {
  const {
    product,
    pageSections,
    consultAvailable,
    consultAvailableText,
    disclaimer,
    comparisonTable,
    heroDescriptionHeader,
    heroDescriptionText,
    heroMeasuresText,
    heroMeasuresIcon,
    heroPurchaseDetailsCallout,
    heroPurchaseDetailsBottomCallout,
    heroSliderImages,
    heroMeasuresContentSection,
    heroModalTitle,
    heroModalButtonText,
    hideSubscription,
    preSelectedSubscription,
    heroSubscriptionFaqUrl,
    heroDescriptionCallout,
    productSchema,
    faqSchema,
    metaTitle,
    metaDescription,
    metaKeywords,
    canonicalUrl,
    screener,
  } = contentfulProduct;
  if (!product) return; // necessary for preview envs to work
  const { category } = product;

  // Spree Slugs must match Contentful Slugs
  const spreeProduct = spreeProductsBySlug[product.slug];

  if (!spreeProduct) {
    return;
  }

  // combine the Contentful Product data with Spree product data
  return {
    ...product,
    price: spreeProduct.price,
    productId: spreeProduct.productId,
    sku: spreeProduct.sku,
    slug: spreeProduct.slug,
    upc: spreeProduct.upc,
    canonicalUrl: canonicalUrl,
    variantId: spreeProduct.variantId,
    disclaimer: disclaimer,
    comparisonTable: comparisonTable,
    screener: screener,
    name: spreeProduct.name,
    category: category && category.title ? category.title : '',
    subscriptionVariants: spreeProduct.subscriptionVariants,
    reviewData: allReviews.find(
      (rev) => Number(rev.product_page_id) === spreeProduct.productId,
    ),
    metaTitle: metaTitle || spreeProduct.metaTitle,
    metaDescription:
      metaDescription?.metaDescription || spreeProduct.metaDescription,
    metaKeywords: metaKeywords?.metaKeywords || spreeProduct.metaKeywords,
    productSchema,
    faqSchema,
    pageSections: pageSections,
    heroSection: {
      heroDescriptionHeader,
      heroDescriptionText: heroDescriptionText?.heroDescriptionText,
      consultAvailable,
      consultAvailableText,
      heroMeasuresText,
      heroMeasuresIcon,
      heroPurchaseDetailsCallout,
      heroPurchaseDetailsBottomCallout,
      heroMeasuresContentSection,
      heroModalTitle,
      heroModalButtonText,
      heroSliderImages,
      heroDescriptionCallout,
    },
    hideSubscription,
    preSelectedSubscription,
    heroSubscriptionFaqUrl,
    promotionable: spreeProduct.promotionable,
    maxQuantity: spreeProduct.maxQuantity,
  };
};

/*
 * map product data fetched from contentful to results in Spree for PDP
 * TODO: this is only being used for the PDP so its specific to the PDP
 * its looking `allContentfulPdpPage` in the result parameter
 */
const getAllPages = (
  contentfulPdpPage: PromoDataResult,
  productVariants: ProductVariants,
  reviews: Reviews,
) => {
  const spreeProductsBySlug = getSpreeProductsBySlug(productVariants);
  const pdpPages: PageProductPromo[] = [];

  contentfulPdpPage?.nodes?.forEach((node) => {
    pdpPages.push(node);
  });

  // use slug in Contentful Product to marry with the Spree Product
  const allPages = pdpPages.map((contentfulProduct) =>
    getCombinedContenfulSpreeProduct(
      contentfulProduct,
      spreeProductsBySlug,
      reviews.nodes,
    ),
  );

  return allPages;
};

const getProductData = (products: AllProductInfoType[], slug: string) =>
  products.filter((product) => product?.slug === slug);

const getPDPProductData = (
  contentfulPdpPage: PromoDataResult,
  productVariants: ProductVariants,
  reviews: Reviews,
  slug: string,
) => {
  const allPages = getAllPages(contentfulPdpPage, productVariants, reviews);
  const productData = getProductData(allPages, slug);

  return productData[0];
};

const getUpdatedProductBySlug = (
  contentfulPdpPage: PromoDataResult,
  productVariants: ProductVariants,
  reviews: Reviews,
  productsBySlug: ProductsBySlugType,
) => {
  const newProductBySlug = { ...productsBySlug };
  const contentfulDataBySlug: any = [];
  const allPages = getAllPages(contentfulPdpPage, productVariants, reviews);

  allPages.forEach((page: any) => {
    if (page?.screener && page?.disclaimer) {
      contentfulDataBySlug.push({
        slug: page.slug,
        screener: page.screener,
        disclaimer: page.disclaimer,
      });
    }
  });

  // Add the screener and disclaimer contentful nodes to the products to be used
  Object.keys(newProductBySlug).forEach((key: string) => {
    const screenerProduct = contentfulDataBySlug.find(
      (product: any) => product.slug === key,
    );

    if (screenerProduct) {
      newProductBySlug[key].screener = screenerProduct.screener;
      newProductBySlug[key].disclaimer = screenerProduct.disclaimer;
    }
  });

  return newProductBySlug;
};

const getProductBadges = (
  productInfo: AllProductInfoType,
): ProductBadgeConfig[] => {
  const badges: ProductBadgeConfig[] = [];

  if (productInfo.isNew) {
    badges.push(PRODUCT_BADGES.NEW);
  }

  if (productInfo.onSale) {
    badges.push(PRODUCT_BADGES.SALE);
  }

  return badges;
};

export {
  capitalize,
  daysUntil,
  filterUniqueByCategory,
  findAllByKey,
  formatDate,
  formatForCurrency,
  formatPrice,
  generateKey,
  getMembershipTypeFromProduct,
  getSkuKey,
  getPDPProductData,
  getProductsBySlug,
  getSpreeProductsBySlug,
  getUpdatedProductBySlug,
  getVariantId,
  logError,
  loginUrl,
  membershipIntakeUrl,
  numericDate,
  hashSha1,
  scrollToId,
  snakeToTitle,
  timeAgo,
  trackIterable,
  truncateString,
  useClickOff,
  useDisableBodyScroll,
  useEffectAsyncWrapper,
  useHasScrollableContent,
  toKebabCase,
  getProductBadges,
};
